import React, { FC } from 'react';

import { tokens } from '../../util/theme/fluent9';
import IconButton from '../IconButton';

import { AppLayoutContextValue } from './AppLayoutContextProvider.types';
import { SidebarPanelButtonProps } from './SidebarPanelButton.types';
import { useAppLayoutContext } from './hooks';

const SidebarPanelButton: FC<SidebarPanelButtonProps> = ({ iconName = 'OpenPane' }) => {
  const { openContentSidebarAriaLabel, openSidebarPanel } = useAppLayoutContext() as AppLayoutContextValue;

  return (
    <IconButton
      size={32}
      ariaLabel={openContentSidebarAriaLabel}
      iconName={iconName}
      backgroundHoverColor="neutralLighter"
      borderRadius={tokens.borderRadiusSmall}
      onClick={openSidebarPanel}
      className="qaSidebarPanelButton"
    />
  );
};

export default SidebarPanelButton;
