import { Message, PartialMessage, PlainMessage, ProtobufMessageClass } from './types';

export const encodeMessage = <T extends Message<T>>(
  MessageClass: ProtobufMessageClass<T>,
  plainMessage: PlainMessage<T>
) => {
  const message = new MessageClass(plainMessage as unknown as PartialMessage<T>);

  return {
    value: message.toBinary(),
    typeName: message.getType().typeName,
  };
};
