import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DataLoaderModule, PageLoadData } from '../types';

type UsePreloadPageDataCallback = (
  setPageLoadData: React.Dispatch<React.SetStateAction<PageLoadData>>
) => (dataLoader: () => Promise<DataLoaderModule>, routeParams: Record<string, unknown>) => void;
export const usePreloadPageDataCallback: UsePreloadPageDataCallback = (setPageLoadData) => {
  const dispatch = useDispatch();

  return useCallback(
    async (dataLoader, routeParams) => {
      const { loadData } = await dataLoader();
      const data = await loadData(dispatch, routeParams);

      if (data) {
        setPageLoadData?.(data);
      }
    },
    [setPageLoadData, dispatch]
  );
};
