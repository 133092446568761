import { ParsedUrlQuery, stringify } from 'querystring';

import { CurrentNetworkContext, Thread } from '@ms/yammer-data/dist/state/types';
import { SearchLocationParameters } from '@ms/yammer-search-types';
import { LocationDescriptor } from 'history';

import { GetBaseUrlProps, getBaseUrl } from '../location/getBaseUrl';

import { SearchQueryParameters, makeSearchQueryStringParameters } from './search';

export const threadRoutePath = '/threads/:threadId/:topLevelMessageId?/:secondLevelMessageId?';

interface MoreForYouQueryParameters {
  readonly showMoreForYou: boolean;
}

interface CopyLinkQueryParameters {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly trk_copy_link: string;
}

interface ThreadViewParameters {
  readonly landAtBottom: boolean;
}

interface ThreadEditParameters {
  readonly edit: boolean;
}

export type ThreadPageQueryOptions =
  | SearchQueryParameters
  | MoreForYouQueryParameters
  | CopyLinkQueryParameters
  | ThreadViewParameters
  | ThreadEditParameters;

const getLocationSearchQueryString = (queryOptions?: ThreadPageQueryOptions) =>
  queryOptions ? `?${stringify(queryOptions as unknown as ParsedUrlQuery)}` : undefined;

type ThreadPage = (id: Thread['id'], queryOptions?: ThreadPageQueryOptions) => string;
export const threadPage: ThreadPage = (id, queryOptions) => {
  const queryString = getLocationSearchQueryString(queryOptions) ?? '';

  return `/threads/${id}${queryString}`;
};

type ThreadPageWithSearchParameters = (id: Thread['id'], searchParams: SearchLocationParameters) => string;
export const threadPageWithSearchParameters: ThreadPageWithSearchParameters = (id, searchParams) => {
  const queryOptions = makeSearchQueryStringParameters(searchParams);

  return threadPage(id, queryOptions);
};

export interface StateWithPrevPathname {
  readonly prevPathname: string;
}
type ThreadPageWithPreviousPathname = (
  id: Thread['id'],
  prevPathname: string,
  options?: ThreadPageQueryOptions
) => LocationDescriptor<StateWithPrevPathname>;
export const threadPageWithPreviousPathname: ThreadPageWithPreviousPathname = (id, prevPathname, options) => ({
  pathname: threadPage(id),
  search: getLocationSearchQueryString(options),
  state: { prevPathname },
});

interface threadUrlProps {
  readonly threadId: string;
  readonly options?: ThreadPageQueryOptions;
  readonly currentNetworkContext?: CurrentNetworkContext;
  readonly host: GetBaseUrlProps['host'];
}
type ThreadUrl = (props: threadUrlProps) => string;
export const threadUrl: ThreadUrl = ({ threadId, options, currentNetworkContext, host }) => {
  const baseUrl = getBaseUrl({ currentNetworkContext, host });

  return `${baseUrl}${threadPage(threadId, options)}`;
};
