import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageDataLoadContextProviderProps } from './PageDataLoadContextProvider.types';
import { PageDataLoadContext, PageDataLoadContextValue } from './context';
import { usePreloadPageDataCallback } from './hooks';
import { PageLoadData } from './types';

const PageDataLoadContextProvider: FC<PageDataLoadContextProviderProps> = ({ dataLoader, children }) => {
  const [pageLoadData, setPageLoadData] = useState<PageLoadData>(undefined);
  const preloadPageData = usePreloadPageDataCallback(setPageLoadData);
  const { pathname, search } = useLocation();
  const routeParams = useParams();
  const hasPreloaded = useRef(false);

  const resolvedParams = useMemo(() => {
    const queryParams = new URLSearchParams(search);

    return {
      pathname,
      ...routeParams,
      ...Object.fromEntries(queryParams.entries()),
    };
  }, [pathname, routeParams, search]);

  useEffect(() => {
    if (!hasPreloaded.current && dataLoader) {
      preloadPageData(dataLoader, resolvedParams);
      hasPreloaded.current = true;
    }
  }, [dataLoader, preloadPageData, resolvedParams]);

  const value = useMemo<PageDataLoadContextValue>(
    () => ({
      pageLoadData,
    }),
    [pageLoadData]
  );

  return <PageDataLoadContext.Provider value={value}>{children}</PageDataLoadContext.Provider>;
};

export default PageDataLoadContextProvider;
