import React, { forwardRef } from 'react';

import { useClassNameAndInlineStyles } from './Flex.styles';
import { FlexProps } from './Flex.types';

const Flex = forwardRef<HTMLDivElement, FlexProps>(({ children, ariaHidden, role, id, ...styleProps }, ref) => {
  const flexStyling = useClassNameAndInlineStyles(styleProps);

  return (
    <div
      role={role}
      id={id}
      aria-hidden={ariaHidden}
      className={flexStyling.className}
      style={flexStyling.inlineStyles}
      ref={ref}
    >
      {children}
    </div>
  );
});

Flex.displayName = 'Flex';

/**
 * A `Flex` component renders a flex-parent div with common flex-container styles.
 */
export default Flex;
