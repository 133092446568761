import {
  cancelFirstPageLoadMeasure,
  reportFirstPageLoad,
  requestAnimationFrameAfterStyleAndLayout,
} from '@ms/yammer-data/dist/telemetry';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  cancelPageLoadTimingEvent,
  clearCachedAppPageLoadTracking,
  endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout,
  endAndReportPageLoadTimingEventAfterStyleAndLayout,
  isCachedAppPageLoad,
  startPageLoadTiming,
  updatePageLoadTimingEvent,
} from '../../../telemetry/pageLoadTiming';
import { HeroComponentName } from '../../AppPageRoutesWithTelemetry/AppPageRoutesWithTelemetry.types';
import { PageTelemetryContextValue } from '../context';

import { useDidVisibilityStateChange } from './useDidVisibilityStateChange';

export type UsePageLoadTelemetryCallbackOptions = Pick<
  PageTelemetryContextValue,
  'page' | 'path' | 'hero' | 'isRoutelessApp'
> & { readonly pathname?: string; readonly pageLoadId: number };
type UsePageLoadTelemetryCallback = (
  options: UsePageLoadTelemetryCallbackOptions
) => (component: HeroComponentName) => void;

/**
 * Reports FirstPageLoad, route-to-route PageLoad, and CachedFirstPageLoad times, handling abandon/cancel and timeout events.
 * Returns a callback to be called by any hero component which manages reporting after the appropriate browser paint delay.
 */
export const usePageLoadTelemetryCallback: UsePageLoadTelemetryCallback = ({
  pathname: pathnameProp,
  pageLoadId,
  path,
  page,
  hero,
  isRoutelessApp,
}) => {
  const { pathname: originalPathName } = useLocation();
  const pathname = pathnameProp || originalPathName;

  useMemo(
    function startNewPageLoadTimingEventImmediatelyAfterRouteChange() {
      startPageLoadTiming({
        pageLoadId,
        eventProperties: { page, path, hero, didVisibilityStateChange: false },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageLoadId]
  );

  const { didVisibilityStateChange, didVisibilityStateChangeRef } = useDidVisibilityStateChange({
    key: `${page}-${pageLoadId}`,
  });

  useEffect(
    function updatePendingPageLoadEventOnVisibilityChanges() {
      if (didVisibilityStateChange) {
        updatePageLoadTimingEvent({
          pageLoadId,
          eventProperties: { page, path, hero, didVisibilityStateChange },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [didVisibilityStateChange]
  );

  useEffect(
    () =>
      function cancelPendingPageLoadEventsOnRouteChange() {
        cancelFirstPageLoadMeasure({
          eventProperties: {
            page,
            path,
            hero,
            didVisibilityStateChange: didVisibilityStateChangeRef.current,
          },
        });

        if (!isCachedAppPageLoad({ isRoutelessApp })) {
          cancelPageLoadTimingEvent({
            pageLoadId,
            eventProperties: {
              page,
              path,
              hero,
              didVisibilityStateChange: didVisibilityStateChangeRef.current,
            },
          });
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return useCallback(
    function reportHeroRendered(component) {
      if (component === hero) {
        const eventProperties = {
          page,
          path,
          hero,
          didVisibilityStateChange: didVisibilityStateChangeRef.current,
        };

        endAndReportPageLoadTimingEventAfterStyleAndLayout({ pageLoadId, eventProperties });

        if (isCachedAppPageLoad({ isRoutelessApp })) {
          endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout({
            eventProperties: {
              ...eventProperties,
              deepLink: true,
            },
          });
          clearCachedAppPageLoadTracking({ isRoutelessApp });
        }

        requestAnimationFrameAfterStyleAndLayout(() => {
          reportFirstPageLoad({ eventProperties });
        });
      }
    },
    [hero, page, path, didVisibilityStateChangeRef, pageLoadId, isRoutelessApp]
  );
};
