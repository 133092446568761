import { DismissablePromptKey } from '@ms/yammer-data/dist/state/types';
import { useLoadDismissablePrompts, useShouldStartDismissablePrompt } from '@ms/yammer-hooks/dist/dismissablePrompts';
import { useHasUserNetworkLeaders } from '@ms/yammer-hooks/dist/userNetworkLeaders';
import { getUrlRootPath } from '@ms/yammer-web-support/dist/location';
import React, { ReactNode, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import LoadableLeadershipCornerBanner from '../LoadableLeadershipCornerBanner';

const promptKeys: DismissablePromptKey[] = ['LeadershipCornerBanner'];

type UseLeadershipCornerBanner = () => ReactNode | null;
export const useLeadershipCornerBanner: UseLeadershipCornerBanner = () => {
  useLoadDismissablePrompts({ promptKeys });
  const { pathname } = useLocation();
  const urlPath = getUrlRootPath(pathname);
  const isLeadershipCornerPageUrl = urlPath === 'leadership-corner';

  const isLeadershipCornerBannerPromptNotDismissed = useShouldStartDismissablePrompt('LeadershipCornerBanner');

  const hasUserNetworkLeaders = useHasUserNetworkLeaders();

  const shouldDisplayLeadershipCornerBanner =
    isLeadershipCornerPageUrl && !hasUserNetworkLeaders && isLeadershipCornerBannerPromptNotDismissed;

  if (!shouldDisplayLeadershipCornerBanner) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LoadableLeadershipCornerBanner />
    </Suspense>
  );
};
