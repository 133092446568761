import { makeStyles } from '@griffel/react';

import { tokens } from '../../../../util/theme/fluent9';

export const useClassNames = makeStyles({
  leftNavContainer: {
    borderRight: `1px solid ${tokens.colorNeutralStroke1}`,
    backgroundColor: tokens.colorNeutralBackground2,
    zIndex: 2,
  },
});
