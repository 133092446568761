import { makeStyles, mergeClasses } from '@griffel/react';

import { useBorderRadiusClassName, useDisplayClassName } from '../../util/styles/fluent9';

import { BorderRadiusName } from './Image.types';

const useClasses = makeStyles({
  fullHeight: { height: '100%' },
  limitMaxWidth: {
    maxWidth: '100%',
    '& > .ms-Image-image': {
      maxWidth: '100%',
    },
  },
  noPointerEvents: {
    pointerEvents: 'none',
    '& > .ms-Image-image': {
      pointerEvents: 'auto',
    },
  },
});

interface StyleProps {
  readonly borderRadius?: BorderRadiusName;
  readonly fullHeight?: boolean;
  readonly fullWidth?: boolean;
  readonly limitMaxWidth?: boolean;
  readonly block?: boolean;
  readonly allowClickThroughOnFrame?: boolean;
  readonly className?: string;
}
export const useClassNames = ({
  borderRadius,
  fullHeight,
  fullWidth,
  limitMaxWidth,
  block,
  className,
  allowClickThroughOnFrame,
}: StyleProps) => {
  const classes = useClasses();
  const display = fullWidth || block ? 'block' : 'inline-block';
  const displayClassName = useDisplayClassName(display);
  const borderRadiusClassName = useBorderRadiusClassName(borderRadius);

  return {
    root: mergeClasses(
      displayClassName,
      borderRadiusClassName,
      fullHeight && classes.fullHeight,
      limitMaxWidth && classes.limitMaxWidth,
      allowClickThroughOnFrame && classes.noPointerEvents,
      className
    ),
  };
};
