/*

This is the implementation of the screenreader announcer. AnnouncerProvider creates
a single instance of this, and routes requests to announce to it. Clients access this
component indirectly via the AnnouncerConsumer component, which they import as 'Announcer'.

See story for example code.

*/

import React, { PureComponent } from 'react';

import AnnouncerContent, { AnnouncerContentState } from './AnnouncerContent';

export type Announce = (text: string) => void;

export interface AnnouncerProps {
  announceRef(announce: Announce): void;
}

export interface AnnouncerState {
  readonly text: string;
  readonly state: AnnouncerContentState;
}

export default class Announcer extends PureComponent<AnnouncerProps, AnnouncerState> {
  private timeout: number | null;

  constructor(props: AnnouncerProps) {
    super(props);

    this.state = {
      text: '',
      state: 'Ready',
    };

    this.props.announceRef(this.announce);
    this.timeout = null;
  }

  public componentDidUpdate(_prevProps: AnnouncerProps, prevState: AnnouncerState) {
    if (this.state.state === prevState.state) {
      return;
    }

    this.startTransitionToNextState();
  }

  public render() {
    return <AnnouncerContent {...this.state} />;
  }

  private readonly announce = (text: string) => {
    this.setState({
      text,
      state: 'Cleared',
    });
  };

  private clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  private scheduleNextState(state: AnnouncerContentState, after: number) {
    this.timeout = window.setTimeout(() => {
      this.setState({ state });
    }, after);
  }

  private startTransitionToNextState() {
    this.clearTimeout();

    const current = this.state.state;
    if (current === 'Cleared') {
      this.setState({ state: 'Added' });
    } else if (current === 'Added') {
      this.scheduleNextState('Ready', 500);
    }
  }
}
