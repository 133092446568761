import { makeStyles, mergeClasses } from '@griffel/react';

import { createTokens } from '../../theme/fluent9';
import { TextWrapStyle } from '../fonts';

import { ClassNameAndInlineStyles } from './types';

export type { TextWrapStyle };

export interface UseTextWrapProps {
  readonly textWrap?: TextWrapStyle;
  readonly maxLines?: number;
}
const { tokens, cssVariables } = createTokens(['maxLines'], 'textWrap');

type UseTextWrap = (props: UseTextWrapProps) => ClassNameAndInlineStyles;
export const useTextWrap: UseTextWrap = (props) => {
  const textWrapClassNames = useTextWrapClassNames();

  const classNames: string[] = [];
  const inlineStyles: Record<string, string | number> = {};

  if (props.textWrap === 'breakWords') {
    classNames.push(textWrapClassNames.breakWords);
  }

  if (props.textWrap === 'breakWordsAndPreserveTextFormatting') {
    classNames.push(textWrapClassNames.preserveTextFormatting, textWrapClassNames.breakWords);
  }

  if (props.textWrap === 'ellipsis') {
    classNames.push(textWrapClassNames.ellipsis);
  }

  const hasValidMaxLines = props.maxLines && props.maxLines > 0;
  if (props.textWrap === 'multiLineEllipsis' && hasValidMaxLines) {
    classNames.push(textWrapClassNames.multiLineEllipsis);
    inlineStyles[cssVariables.maxLines] = props.maxLines;
  }

  if (props.textWrap === 'preserveTextFormatting') {
    classNames.push(textWrapClassNames.preserveTextFormatting);
  }

  if (props.textWrap === 'preventWrap') {
    classNames.push(textWrapClassNames.preventWrap);
  }

  return {
    className: mergeClasses(...classNames),
    inlineStyles,
  };
};

const useTextWrapClassNames = makeStyles({
  breakWords: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  multiLineEllipsis: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: tokens.maxLines,
  },
  preventWrap: {
    whiteSpace: 'nowrap',
  },
  preserveTextFormatting: {
    whiteSpace: 'pre-wrap',
  },
});
