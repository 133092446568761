import { getOcpsPolicies } from '@ms/yammer-data/dist/domains/ocps/selectors';
import { ComplianceCheckOption, ComplianceChecks } from '@ms/yammer-data/dist/domains/ocv/types';
import { YammerState } from '@ms/yammer-data/dist/state/types';
import { useMemo } from 'react';

import { useSelector } from '../useSelector';

export const getComplianceValue = (value: boolean | null): ComplianceCheckOption => {
  if (value == null) {
    return 'Not Configured';
  }

  return value ? 'Enabled' : 'Disabled';
};

export const useComplianceChecks = (): ComplianceChecks => {
  const policies = useSelector((state: YammerState) => getOcpsPolicies(state));

  return useMemo(() => {
    let policyAllowFeedback: ComplianceCheckOption = 'Not Configured';
    let policyAllowSurvey: ComplianceCheckOption = 'Not Configured';
    let connectedExperiences = null;
    let policyAllowScreenshot: ComplianceCheckOption = 'Not Configured';
    let policyAllowContact: ComplianceCheckOption = 'Not Configured';
    let policyAllowContent: ComplianceCheckOption = 'Not Configured';
    if (policies) {
      policyAllowFeedback = getComplianceValue(policies.UserFeedbackFormEnabled);
      policyAllowSurvey = getComplianceValue(policies.SurveyFeedbackFormEnabled);
      if (policies.ConnectedExperiencesEnabled) {
        connectedExperiences = getComplianceValue(policies.ConnectedExperiencesEnabled) as 'Enabled' | 'Disabled';
      }
      policyAllowScreenshot = getComplianceValue(policies.AllowScreenshots);
      policyAllowContact = getComplianceValue(policies.AllowEmailCollection);
      policyAllowContent = getComplianceValue(policies.AllowLogCollection);
    }

    return {
      authenticationType: 'AAD',
      ageGroup: 'Undefined',
      connectedExperiences,
      policyAllowFeedback,
      policyAllowSurvey,
      policyAllowScreenshot,
      policyAllowContact,
      policyAllowContent,
    };
  }, [policies]);
};
