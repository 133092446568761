import { makeStyles, mergeClasses } from '@griffel/react';

// To be resolved here: https://dev.azure.com/yammer/engineering/_workitems/edit/286122
// eslint-disable-next-line no-restricted-imports
import { getSemanticPaletteColor } from '../../util/colors';
import {
  ClassNamesAndInlineStyles,
  getFluentFontSizeTokenFromNumericFontSize,
  getFluentFontSizeTokenFromPreset,
  getFluentLineHeightTokenFromNumericFontSize,
  typographyPresetStyles,
  useFontSizeClassName,
  usePadding,
  useTextAlign,
  useTextWrap,
  useTypographyPresetClassName,
} from '../../util/styles/fluent9';
import { textColors } from '../../util/styles/fonts';
import { getGutterValue } from '../../util/styles/gutters';
// To be resolved here: https://dev.azure.com/yammer/engineering/_workitems/edit/286122
// eslint-disable-next-line no-restricted-imports
import { ITheme } from '../../util/styling';
import { createTokens } from '../../util/theme/fluent9';

import { BlockProps } from './Block.types';

const { tokens, cssVariables } = createTokens(
  ['maxHeight', 'backgroundColor', 'textColor', 'topSpacing', 'bottomSpacing', 'iconSize'],
  'block'
);

/**
 * Remove when icon sizing get simplified:
 * https://dev.azure.com/yammer/engineering/_workitems/edit/273336
 */
const getFontSizeFromPresetOrSize = (textPreset: BlockProps['textPreset'], textSize: BlockProps['textSize']) => {
  if (textPreset) {
    return getFluentFontSizeTokenFromPreset(textPreset);
  }

  if (textSize) {
    return getFluentFontSizeTokenFromNumericFontSize(textSize);
  }
};

const getLineHeightFromPresetOrSize = (textPreset: BlockProps['textPreset'], textSize: BlockProps['textSize']) => {
  if (textPreset) {
    return typographyPresetStyles[textPreset].lineHeight;
  }

  if (textSize) {
    return getFluentLineHeightTokenFromNumericFontSize(textSize);
  }

  return undefined;
};

export interface BlockClassNameProps {
  readonly backgroundColor?: BlockProps['backgroundColor'];
  readonly bottomSpacing?: BlockProps['bottomSpacing'];
  readonly className?: BlockProps['className'];
  readonly dir?: BlockProps['dir'];
  readonly horizontalPadding?: BlockProps['horizontalPadding'];
  readonly maxLines?: BlockProps['maxLines'];
  readonly padding?: BlockProps['padding'];
  readonly paddingBottom?: BlockProps['paddingBottom'];
  readonly paddingLeft?: BlockProps['paddingLeft'];
  readonly paddingRight?: BlockProps['paddingRight'];
  readonly paddingTop?: BlockProps['paddingTop'];
  readonly textAlign?: BlockProps['textAlign'];
  readonly textColor?: BlockProps['textColor'];
  readonly textPreset?: BlockProps['textPreset'];
  readonly textSize?: BlockProps['textSize'];
  readonly textWrap?: BlockProps['textWrap'];
  readonly topSpacing?: BlockProps['topSpacing'];
  readonly verticalPadding?: BlockProps['verticalPadding'];
  /**
   * @deprecated Will be removed with https://dev.azure.com/yammer/engineering/_workitems/edit/271152
   */
  readonly theme: ITheme;
}

type UseClassNamesAndInlineStyles = (props: BlockClassNameProps) => ClassNamesAndInlineStyles<'root' | 'inner'>;
export const useClassNamesAndInlineStyles: UseClassNamesAndInlineStyles = ({
  backgroundColor,
  bottomSpacing,
  className,
  dir,
  horizontalPadding,
  maxLines,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  textAlign,
  textColor,
  textPreset,
  textSize,
  textWrap,
  theme,
  topSpacing,
  verticalPadding,
}) => {
  const blockClassNames = useBlockClassNames();

  const textAlignStyles = useTextAlign({ align: textAlign, dir });
  const fontSizeClassName = useFontSizeClassName(textSize);
  const textWrapStyles = useTextWrap({ textWrap, maxLines });
  const paddingStyles = usePadding({
    padding,
    verticalPadding,
    horizontalPadding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  });

  const hasSize = !!textPreset || !!textSize;
  const hasTopSpacing = topSpacing != null;
  const hasBottomSpacing = bottomSpacing != null;

  const hasMaxHeight = !!maxLines && !!hasSize;
  const maxHeightClassName = hasMaxHeight ? blockClassNames.maxHeight : undefined;
  const lineHeight = getLineHeightFromPresetOrSize(textPreset, textSize);
  const maxHeightStyles = {
    [cssVariables.maxHeight]: hasMaxHeight ? `calc(${lineHeight} * ${maxLines})` : undefined,
  };

  return {
    root: {
      className: mergeClasses(
        'y-block',
        textAlignStyles.className,
        fontSizeClassName,
        paddingStyles.className,
        backgroundColor && blockClassNames.backgroundColor,
        textColor && blockClassNames.textColor,
        hasTopSpacing && blockClassNames.topSpacing,
        hasBottomSpacing && blockClassNames.bottomSpacing,
        hasSize && blockClassNames.nestedSVGIconSize,
        useTypographyPresetClassName(textPreset),
        className
      ),
      inlineStyles: {
        ...textAlignStyles.inlineStyles,
        ...paddingStyles.inlineStyles,
        [cssVariables.backgroundColor]: backgroundColor ? getSemanticPaletteColor(theme, backgroundColor) : undefined,
        [cssVariables.textColor]: textColor ? textColors(theme)[textColor] : undefined,
        [cssVariables.topSpacing]: hasTopSpacing ? getGutterValue(topSpacing) : undefined,
        [cssVariables.bottomSpacing]: hasBottomSpacing ? getGutterValue(bottomSpacing) : undefined,
        [cssVariables.iconSize]: getFontSizeFromPresetOrSize(textPreset, textSize),
      },
    },
    inner: {
      className: mergeClasses(textWrapStyles.className, maxHeightClassName),
      inlineStyles: {
        ...textWrapStyles.inlineStyles,
        ...maxHeightStyles,
      },
    },
  };
};

const useBlockClassNames = makeStyles({
  maxHeight: {
    overflow: 'hidden',
    maxHeight: tokens.maxHeight,
  },
  textColor: {
    color: tokens.textColor,
  },
  backgroundColor: {
    backgroundColor: tokens.backgroundColor,
  },
  topSpacing: {
    marginTop: tokens.topSpacing,
  },
  bottomSpacing: {
    marginBottom: tokens.bottomSpacing,
  },
  /**
   * Remove when icon sizing get simplified:
   * https://dev.azure.com/yammer/engineering/_workitems/edit/273336
   */
  nestedSVGIconSize: {
    '& .y-icon, & .y-fluent-icon': {
      height: tokens.iconSize,
      width: tokens.iconSize,
    },
  },
});
