import { makeStyles } from '@griffel/react';

import { coreuiTokens, tokens } from '../../theme/fluent9';
import { BorderRadiusName } from '../borders';

const useBorderRadiusClassNames = makeStyles<BorderRadiusName>({
  borderRadiusNone: { borderRadius: tokens.borderRadiusNone },
  borderRadiusSmall: { borderRadius: tokens.borderRadiusSmall },
  borderRadiusMedium: { borderRadius: tokens.borderRadiusMedium },
  borderRadiusLarge: { borderRadius: tokens.borderRadiusLarge },
  borderRadiusXLarge: { borderRadius: tokens.borderRadiusXLarge },
  borderRadiusXXLarge: { borderRadius: coreuiTokens.borderRadiusXXLarge },
  borderRadiusCircular: { borderRadius: tokens.borderRadiusCircular },
});

export const useBorderRadiusClassName = (radius?: BorderRadiusName) => {
  const classNames = useBorderRadiusClassNames();

  return radius ? classNames[radius] : undefined;
};
