import { AnalyticsSettings, User, YammerState } from '../../state/types';

type GetSelectedOnBehalfOfUserId = (state: YammerState) => User['id'] | undefined;
export const getSelectedOnBehalfOfUserId: GetSelectedOnBehalfOfUserId = (state) =>
  state.analyticsSettings.selectedOnBehalfOfUserId;

type GetAnalyticsSettings = (state: YammerState) => AnalyticsSettings;
export const getAnalyticsSettings: GetAnalyticsSettings = (state) => state.analyticsSettings;

type GetSelectedGroupId = (state: YammerState) => AnalyticsSettings['selectedGroupId'];
export const getSelectedGroupId: GetSelectedGroupId = (state) => state.analyticsSettings.selectedGroupId;

type GetSelectedCampaignId = (state: YammerState) => AnalyticsSettings['selectedCampaignId'];
export const getSelectedCampaignId: GetSelectedCampaignId = (state) => state.analyticsSettings.selectedCampaignId;
