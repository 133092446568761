import { useMemo } from 'react';

import { useCanAccessCommunicationsDashboard, useIsCompactHeaderGeneralEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

import { useCommsDashboardConversationsPageSettings } from './useCommsDashboardConversationsPageSettings';
import { useCommsDashboardDigitalSafetyPageSettings } from './useCommsDashboardDigitalSafetyPageSettings';
import { useCommsDashboardLeadersAndAudiencesPageSettings } from './useCommsDashboardLeadersAndAudiencesPageSettings';

export const useCommsDashboardPageSettings: UseMainUIFeatureSettings<'communicationsDashboardPage'> = (options) => {
  const commsDashboardOverrides = options?.overrides?.communicationsDashboardPage;

  const canAccessDashboard = useCanAccessCommunicationsDashboard();
  const canAccessDigitalSafety = useCommsDashboardDigitalSafetyPageSettings()[uiFeatureAccessSymbol];
  const canAccessLeadersAndAudiences = useCommsDashboardLeadersAndAudiencesPageSettings()[uiFeatureAccessSymbol];
  const isCompactHeaderGeneralEnabled = useIsCompactHeaderGeneralEnabled();
  const canAccessDashboardConversations = useCommsDashboardConversationsPageSettings()[uiFeatureAccessSymbol];

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: canAccessDashboard,
      isCompactHeader: isCompactHeaderGeneralEnabled,
      digitalSafety: canAccessDigitalSafety,
      leadersAndAudiences: canAccessLeadersAndAudiences,
      conversations: canAccessDashboardConversations,
      ...commsDashboardOverrides,
    }),
    [
      canAccessDashboard,
      canAccessDashboardConversations,
      canAccessDigitalSafety,
      canAccessLeadersAndAudiences,
      commsDashboardOverrides,
      isCompactHeaderGeneralEnabled,
    ],
  );
};
