import { PageName } from '@ms/yammer-telemetry-support';
import { createContext } from 'react';

import { HeroComponentName } from '../AppPageRoutesWithTelemetry/AppPageRoutesWithTelemetry.types';

export interface RouteProps {
  readonly page: PageName;
  readonly path?: string;
  readonly hero: HeroComponentName;
}

export interface PageTelemetryContextValue extends RouteProps {
  readonly isRoutelessApp: boolean;
  readonly reportHeroComponentRendered: (component: HeroComponentName) => void;
  readonly hasHeroComponentRendered: boolean;
  readonly setRoute: (route: RouteProps, pathname: string) => void;
}

export const PageTelemetryContext = createContext<PageTelemetryContextValue | undefined>(undefined);
