import { makeStyles, mergeClasses } from '@griffel/react';

import { Fluent9ComponentClassNames } from '../../util/classNames';
import { px } from '../../util/styling';
import { createTokens } from '../../util/theme/fluent9';

import { ScrollableProps } from './Scrollable.types';

const trackSize = 10;
const handleSize = 6;
const gutterSize = (trackSize - handleSize) / 2;

const { tokens, cssVariables } = createTokens(
  ['height', 'maxHeight', 'width', 'scrollbarOffset', 'backgroundColor', 'scrollPaddingTop'],
  'scrollable'
);

type GetInlineStylesProps = Pick<ScrollableProps, 'height' | 'maxHeight' | 'width' | 'scrollbarOffset'> & {
  readonly backgroundColor: string;
  readonly scrollPaddingTop?: string;
};
export const getInlineStyles = ({
  height,
  maxHeight,
  width,
  scrollbarOffset,
  backgroundColor,
  scrollPaddingTop,
}: GetInlineStylesProps) => ({
  [cssVariables.height]: px(height),
  [cssVariables.maxHeight]: px(maxHeight),
  [cssVariables.width]: px(width),
  [cssVariables.scrollbarOffset]: px(scrollbarOffset),
  [cssVariables.backgroundColor]: backgroundColor,
  [cssVariables.scrollPaddingTop]: scrollPaddingTop,
});

const getScrollbarDirectionalStyles = (isHorizontal: boolean) => {
  const lengthDimension = isHorizontal ? 'height' : 'width';
  const offsetDimension = isHorizontal ? 'bottom' : 'right';
  const horizontalPadding = isHorizontal ? gutterSize : 0;
  const verticalPadding = isHorizontal ? 0 : gutterSize;
  const directionalSelector = isHorizontal
    ? '> .os-scrollbar.os-scrollbar-horizontal'
    : '> .os-scrollbar.os-scrollbar-vertical';

  return {
    [directionalSelector]: {
      padding: `${px(verticalPadding)} ${px(horizontalPadding)}`,
      [lengthDimension]: px(trackSize),
      [offsetDimension]: tokens.scrollbarOffset,
      transitionProperty: 'opacity, visibility',

      '> .os-scrollbar-track': {
        [lengthDimension]: px(trackSize),

        '> .os-scrollbar-handle': {
          [lengthDimension]: px(handleSize),
          [offsetDimension]: px(gutterSize),
          borderRadius: px(handleSize) as string,
          backgroundColor: tokens.backgroundColor,
          transitionProperty: 'transform',

          ':hover, :active': {
            [lengthDimension]: px(trackSize),
            [offsetDimension]: 0,
          },
        },
      },
      ':hover .os-scrollbar-track > .os-scrollbar-handle': {
        backgroundColor: tokens.backgroundColor,
      },
    },
  };
};

type UseClassNames = (isHorizontal: boolean) => Fluent9ComponentClassNames<'scrollable'>;
export const useClassNames: UseClassNames = (isHorizontal) => {
  const scrollableClasses = useScrollableClasses();

  return {
    scrollable: mergeClasses(
      scrollableClasses.scrollable,
      isHorizontal && scrollableClasses.scrollable_horizontal,
      !isHorizontal && scrollableClasses.scrollable_vertical
    ),
  };
};

const useScrollableClasses = makeStyles({
  scrollable: {
    height: tokens.height,
    maxHeight: tokens.maxHeight,
    width: tokens.width,
    '> .os-viewport.os-viewport-scrollbar-hidden': {
      scrollPaddingTop: tokens.scrollPaddingTop,
    },
  },

  scrollable_horizontal: getScrollbarDirectionalStyles(true),
  scrollable_vertical: getScrollbarDirectionalStyles(false),
});
