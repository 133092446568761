import React, { FC } from 'react';

import { useTheme } from '../../util/theme';
import { tokens } from '../../util/theme/fluent9';
import Clickable from '../Clickable';
import FluentUISystemIcon from '../FluentUISystemIcon';
import Icon from '../Icon';
import Image from '../Image';
import InternalLink from '../InternalLink';

import { getClassNames } from './IconButton.styles.legacy';
import {
  FluentUISystemIconButtonProps,
  IconButtonProps,
  ImageIconButtonProps,
  NamedIconButtonProps,
  NavigableIconButtonProps,
} from './IconButton.types';

const isNamedIconButtonProps = (iconProps: IconButtonProps): iconProps is NamedIconButtonProps =>
  !!(iconProps as NamedIconButtonProps).iconName;

const isImageIconButtonProps = (iconProps: IconButtonProps): iconProps is ImageIconButtonProps =>
  !!(iconProps as ImageIconButtonProps).iconSource;

const isFluentUISystemIconIconButtonProps = (iconProps: IconButtonProps): iconProps is FluentUISystemIconButtonProps =>
  !!(iconProps as FluentUISystemIconButtonProps).Icon;
/**
 * `IconButton` is a square button which displays an icon.
 */
export const IconButton: FC<IconButtonProps> = (props) => {
  const {
    ariaLabel,
    backgroundColor,
    backgroundHoverColor,
    useTransparentBackground = false,
    borderRadius = tokens.borderRadiusMedium,
    className,
    role,
    disabled,
    size = 18,
    onClick,
    onFocus,
    onHover,
    to,
    badge,
    badgeAriaLabel,
  } = props;
  const focusableRef = (props as NavigableIconButtonProps).focusableRef;

  const theme = useTheme();
  const color = isNamedIconButtonProps(props) ? props.color : undefined;
  const classNames = getClassNames({
    size,
    backgroundColor,
    backgroundHoverColor,
    useTransparentBackground,
    borderRadius,
    theme,
    color,
    className,
  });

  const buttonAriaLabel = badge ? `${ariaLabel} - ${badgeAriaLabel}` : ariaLabel;
  const iconBadge = badge ? <div className={classNames.badge}>{badge}</div> : null;

  const getIcon = () => {
    if (isNamedIconButtonProps(props)) {
      return <Icon iconName={props.iconName} className={classNames.icon} />;
    }

    if (isImageIconButtonProps(props)) {
      return <Image description="" source={props.iconSource} />;
    }

    // istanbul ignore else
    if (isFluentUISystemIconIconButtonProps(props)) {
      return <FluentUISystemIcon Icon={props.Icon} size={16} />;
    }
  };

  if (to) {
    return (
      <InternalLink
        ariaLabel={buttonAriaLabel}
        onClick={onClick}
        onFocus={onFocus}
        onHover={onHover}
        className={classNames.iconButton}
        unstyled={true}
        role={role}
        to={to}
        focusableRef={focusableRef}
      >
        {iconBadge}
        {getIcon()}
      </InternalLink>
    );
  }

  return (
    <Clickable
      ariaLabel={buttonAriaLabel}
      onClick={onClick}
      onFocus={onFocus}
      onHover={onHover}
      className={classNames.iconButton}
      unstyled={true}
      role={role}
      disabled={disabled}
      focusableRef={focusableRef}
    >
      {iconBadge}
      {getIcon()}
    </Clickable>
  );
};

export default IconButton;
