// https://nolanlawson.com/2018/09/25/accurately-measuring-layout-on-the-web/
type RequestAnimationFrameAfterStyleAndLayout = (callback: (calledAfterStyleAndLayout: boolean) => void) => void;
export const requestAnimationFrameAfterStyleAndLayout: RequestAnimationFrameAfterStyleAndLayout = (callback) => {
  const isVisibilityStateHidden = window.document?.visibilityState !== 'visible';
  if (isVisibilityStateHidden) {
    callback(false);
  } else {
    requestAnimationFrame(() => {
      setTimeout(() => {
        callback(true);
      });
    });
  }
};
