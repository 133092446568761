import { useViewerCanCreateArticle } from '@ms/yammer-hooks/dist/publishers';
import { useMemo } from 'react';

import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useArticlePublisherSettings: UseMainUIFeatureSettings<'articlePublisher'> = (options) => {
  const articlePublisherOverrides = options?.overrides?.articlePublisher;
  const isArticlePublisherEnabled = useViewerCanCreateArticle();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isArticlePublisherEnabled,
      isArticlePublisherEnabled,
      ...articlePublisherOverrides,
    }),
    [isArticlePublisherEnabled, articlePublisherOverrides]
  );
};
