import { NotificationPaginations, YammerState } from '../../state/types';

export const notificationPageSize = 20;
export const maxCountToShow = 20;

type GetUnseenCount = (state: YammerState) => number;
export const getUnseenCount: GetUnseenCount = (state) =>
  state.notificationPaginations != null ? state.notificationPaginations.unseenCount : 0;

type GetNotificationPaginations = (state: YammerState) => NotificationPaginations;
export const getNotificationPaginations: GetNotificationPaginations = (state) => state.notificationPaginations;
