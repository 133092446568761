import { useMemo } from 'react';

import { useIsExternalNetworksSettingsEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useExternalNetworksSettingsAdminPage: UseMainUIFeatureSettings<'externalNetworksSettingsAdminPage'> = (
  options
) => {
  const externalNetworksAdminSettingsPageSettingsOverrides = options?.overrides?.externalNetworksSettingsAdminPage;

  const externalNetworksAdminSettingsPageSettingsPolicy = useIsExternalNetworksSettingsEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: externalNetworksAdminSettingsPageSettingsPolicy,
      ...externalNetworksAdminSettingsPageSettingsOverrides,
    }),
    [externalNetworksAdminSettingsPageSettingsOverrides, externalNetworksAdminSettingsPageSettingsPolicy]
  );
};
