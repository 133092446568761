import { PageName } from '@ms/yammer-telemetry-support';

import { AppRoutes, parentRouteHasRedirectRoute } from './AppPageRoutesWithTelemetry.types';
import { isParentRoute } from './isParentRoute';

const throwDuplicatePageError = (page: PageName) => {
  throw new Error(`Duplicate page name: ${page}`);
};

export const enforceNoDuplicatePageNames = (routes: AppRoutes) => {
  const checkedPageNames: Partial<Record<PageName, true>> = {};

  const trackPageAndReportIfDuplicate = (page: PageName) => {
    if (checkedPageNames[page]) {
      throwDuplicatePageError(page);
    }
    checkedPageNames[page] = true;
  };

  const checkRoutes = (routes: AppRoutes) => {
    routes.forEach((route) => {
      if (isParentRoute(route)) {
        checkRoutes(route.childRoutes);

        if (parentRouteHasRedirectRoute(route)) {
          trackPageAndReportIfDuplicate(route.fallbackRedirectRoute.page);
        }
      } else {
        trackPageAndReportIfDuplicate(route.page);
      }
    });
  };

  checkRoutes(routes);
};
