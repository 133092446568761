import { getFirstTabbable, isElementVisibleAndNotHidden } from '@fluentui/react/lib/Utilities';
import { FC, useEffect } from 'react';

import { isMac } from '../../util/browserDetector';

// Heading/section navigation:
//
// Use F6 / Shift+F6 to navigate from section to section of current page.
// Uses the existing H2 structure; F6 moves to the next H2, Shift-F6 to the previous H2.
//
// Takes into account:
// * Headings that contain links, buttons, or other tabbable items (defers to first tab within)
// * display:none/visibility:hidden'd headings (skipped over)
// * 'accessibility hidden' headings (skipes to next focusable item after)
// * Works with both actual H2s, and other elements marked up with ARIA to behave as H2 (ie those
//   with role="heading" and aria-level="2")

export const handleHeadingNavigation = (start: Element | null, reverse: boolean) => {
  // istanbul ignore next
  if (!start) {
    return null;
  }

  // Get array of candidate headings - and current position within them...
  start.classList.add('x-current-focused-element');
  const headingsWithCurrent = Array.from(
    document.querySelectorAll('body, h2, [role=heading][aria-level="2"], .x-current-focused-element')
  );
  start.classList.remove('x-current-focused-element');

  const startIndex = headingsWithCurrent.indexOf(start);
  let currentIndex = startIndex;
  let candidateHeading;

  // Find nearest visible candidate heading in appropriate direction. If working
  // backwards, take additional step, since previous heading is the heading of
  // the section we're currently in, and we want the one before that again.
  if (reverse) {
    currentIndex = (currentIndex + headingsWithCurrent.length - 1) % headingsWithCurrent.length;
  }

  for (;;) {
    currentIndex =
      (reverse ? currentIndex + headingsWithCurrent.length - 1 : currentIndex + 1) % headingsWithCurrent.length;
    if (currentIndex === startIndex) {
      break;
    }

    candidateHeading = headingsWithCurrent[currentIndex] as HTMLElement;

    const role = candidateHeading.getAttribute('role');
    if (!role || role === 'heading') {
      if (isElementVisibleAndNotHidden(candidateHeading)) {
        const candidateFocusable = getFirstTabbable(document.body, candidateHeading);
        if (candidateFocusable && candidateFocusable !== start) {
          return candidateFocusable;
        }
      }
    }
  }

  return null;
};

const isHeadingNavigationCommand = (event: KeyboardEvent) => {
  if (event.key !== 'F6') {
    return false;
  }

  // Mac: optionally shift, optionally cmd; no other modifiers
  if (isMac()) {
    return !event.ctrlKey && !event.altKey;
  }

  // PC: optionally shift, optionally ctrl; no other modifiers
  return !event.metaKey && !event.altKey;
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (!isHeadingNavigationCommand(event)) {
    return;
  }

  const focusCandidate = handleHeadingNavigation(document.activeElement, event.shiftKey);

  if (focusCandidate) {
    focusCandidate.focus();
  }

  return false;
};

interface KeyboardHeadingNavigationProps {
  readonly enable: boolean;
}

const KeyboardHeadingNavigation: FC<KeyboardHeadingNavigationProps> = ({ enable }) => {
  useEffect(() => {
    if (!enable) {
      return;
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enable]);

  return null;
};

export default KeyboardHeadingNavigation;
