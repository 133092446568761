import React, { FC } from 'react';

import { StyleGuideContext, StyleGuideOptions, defaultOptions } from './context';

export interface StyleGuideOptionsProviderProps {
  readonly options?: Partial<StyleGuideOptions>;
}

const StyleGuideOptionsProvider: FC<StyleGuideOptionsProviderProps> = ({ options = {}, children }) => (
  <StyleGuideContext.Provider value={{ ...defaultOptions, ...options }}>{children}</StyleGuideContext.Provider>
);

export default StyleGuideOptionsProvider;
