import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';

const GlobalCopilotFloatieButton = async () => {
  const { GlobalCopilotFloatieButton } = await import(
    /* webpackChunkName: "4-feat-global-copilot-floatie-button" */ './GatedGlobalCopilotFloatieButton'
  );

  return { default: GlobalCopilotFloatieButton };
};

export const LoadableGlobalCopilotFloatieButton = lazyLoadOnRender({
  loader: GlobalCopilotFloatieButton,
});
