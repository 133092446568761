import { getOcpsPolicies } from '@ms/yammer-data/dist/domains/ocps/selectors';

import { useSelector } from '../useSelector';

export const useIsFeedbackEnabled = (policy: 'UserFeedbackFormEnabled' | 'SurveyFeedbackFormEnabled') =>
  useSelector((state) => {
    const policies = getOcpsPolicies(state);

    return !!policies && (policies[policy] === true || policies[policy] == null);
  });
