import { GroupFeedType, MyLeadersFeedType } from '@ms/yammer-graphql';

import { Feed, Group, UnseenThreadEdgesForFeed, YammerState } from '../../state/types';
import { getId, inboxUnreadFeedId, networkQuestionInboxUnreadFeedId } from '../feeds/getId';
import { createFindById } from '../findById';
import { getUnseenCount } from '../notificationPaginations/selectors';
import { isPending } from '../requestStatuses/selectors';

export const maxDocumentHeaderCount = 20;
export const maxInboxCountToShow = 20;
export const maxGroupCountToShow = 20;
const countBuffer = 5;

const findUnseenThreadEdgesById = createFindById('unseenThreadEdges');

type GetFeedUnseenThreadCount = (state: YammerState, feedId: Feed['id']) => number;
export const getFeedUnseenThreadCount: GetFeedUnseenThreadCount = (state, feedId) => {
  const unseenThreadEdges = findUnseenThreadEdgesById(state, feedId);

  return unseenThreadEdges ? unseenThreadEdges.threadEdges.length : 0;
};

type IsFeedWithMoreThreadsOnServer = (state: YammerState, feedId: Feed['id']) => boolean;
const isFeedWithMoreThreadsOnServer: IsFeedWithMoreThreadsOnServer = (state, feedId) => {
  const unseenThreadEdges = findUnseenThreadEdgesById(state, feedId);

  return !!unseenThreadEdges && unseenThreadEdges.hasOlderThreads;
};

interface CountMap {
  readonly count: number;
  readonly maxCountToShow: number;
}

type GetDisplayableFeedCount = (state: YammerState, feedId: Feed['id'], countMap: CountMap) => string | undefined;
const getDisplayableFeedCount: GetDisplayableFeedCount = (state, feedId, { maxCountToShow, count }) => {
  if (count <= 0) {
    return;
  }

  if (isFeedWithMoreThreadsOnServer(state, feedId) || count >= maxCountToShow) {
    return `${maxCountToShow}+`;
  }

  return `${count}`;
};

type GetDisplayableUnseenGroupCount = (state: YammerState, groupId: Group['id']) => string | undefined;
export const getDisplayableUnseenGroupCount: GetDisplayableUnseenGroupCount = (state, groupId) => {
  const feedId = getId({ type: 'Group', subtype: GroupFeedType.UNSEEN, entityId: groupId });
  const count = getFeedUnseenThreadCount(state, feedId);

  return getDisplayableFeedCount(state, feedId, { count, maxCountToShow: maxGroupCountToShow });
};

type GetUnseenGroupCount = (state: YammerState, groupId: Group['id']) => number;
export const getUnseenGroupCount: GetUnseenGroupCount = (state, groupId) => {
  const feedId = getId({ type: 'Group', subtype: GroupFeedType.UNSEEN, entityId: groupId });

  return getFeedUnseenThreadCount(state, feedId);
};

type HasUnseenGroupFeedThreads = (state: YammerState, groupId: Group['id']) => boolean;
export const hasUnseenGroupFeedThreads: HasUnseenGroupFeedThreads = (state, groupId) => {
  const feedId = getId({ type: 'Group', subtype: GroupFeedType.UNSEEN, entityId: groupId });

  return getFeedUnseenThreadCount(state, feedId) > 0;
};

type GetUnseenGroupFeedThreads = (state: YammerState, groupIds: Group['id'][]) => Group['id'][];
export const getGroupIdsWithUnseenGroupFeedThreads: GetUnseenGroupFeedThreads = (state, groupIds) =>
  groupIds.filter((groupId) => hasUnseenGroupFeedThreads(state, groupId));

type GetDisplayableUnreadInboxCount = (state: YammerState) => string | undefined;
export const getDisplayableUnreadInboxCount: GetDisplayableUnreadInboxCount = (state) => {
  const count = getFeedUnseenThreadCount(state, inboxUnreadFeedId);

  return getDisplayableFeedCount(state, inboxUnreadFeedId, { count, maxCountToShow: maxInboxCountToShow });
};

type GetUnreadInboxCount = (state: YammerState) => number;
export const getUnreadInboxCount: GetUnreadInboxCount = (state) => getFeedUnseenThreadCount(state, inboxUnreadFeedId);

type HasUnreadInboxThreads = (state: YammerState) => boolean;
export const hasUnreadInboxThreads: HasUnreadInboxThreads = (state: YammerState) =>
  getFeedUnseenThreadCount(state, inboxUnreadFeedId) > 0;

type HasUnreadNetworkQuestionInboxThreads = (state: YammerState) => boolean;
export const hasUnreadNetworkQuestionInboxThreads: HasUnreadNetworkQuestionInboxThreads = (state: YammerState) =>
  getFeedUnseenThreadCount(state, networkQuestionInboxUnreadFeedId) > 0;

type GetUnreadNetworkQuestionInboxCount = (state: YammerState) => string | undefined;
export const getUnreadNetworkQuestionInboxCount: GetUnreadNetworkQuestionInboxCount = (state) => {
  const count = getFeedUnseenThreadCount(state, networkQuestionInboxUnreadFeedId);

  return getDisplayableFeedCount(state, networkQuestionInboxUnreadFeedId, {
    count,
    maxCountToShow: maxInboxCountToShow,
  });
};

type HasUnseenMyLeadersThreads = (state: YammerState) => boolean;
export const hasUnseenMyLeadersThreads: HasUnseenMyLeadersThreads = (state) => {
  const feedId = getId({ type: 'MyLeaders', subtype: MyLeadersFeedType.UNSEEN });

  return getFeedUnseenThreadCount(state, feedId) > 0;
};

type IsInboxCountRefreshNeeded = (state: YammerState) => boolean;
export const isInboxCountRefreshNeeded: IsInboxCountRefreshNeeded = (state) => {
  const unseenThreadEdgesForInboxUnread = findUnseenThreadEdgesById(state, inboxUnreadFeedId);
  const count = getFeedUnseenThreadCount(state, inboxUnreadFeedId);
  const minCount = maxInboxCountToShow + countBuffer;
  const isCountLow = count < minCount;

  return !!unseenThreadEdgesForInboxUnread && unseenThreadEdgesForInboxUnread.hasOlderThreads && isCountLow;
};

type IsGroupFeedUnreadCountRequestPending = (state: YammerState, groupId: Group['id']) => boolean;
export const isGroupFeedUnreadCountRequestPending: IsGroupFeedUnreadCountRequestPending = (state, groupId) =>
  isPending(state, 'GroupFeedUnreadCount', groupId);

interface GetDisplayableDocumentHeaderCountOptions {
  readonly showInboxCount?: boolean;
  readonly showNotificationCount?: boolean;
  readonly maxCountToShow?: number;
}

type GetDisplayableDocumentHeaderCount = (
  state: YammerState,
  options?: GetDisplayableDocumentHeaderCountOptions
) => string | undefined;
export const getDisplayableDocumentHeaderCount: GetDisplayableDocumentHeaderCount = (
  state,
  { showInboxCount = true, showNotificationCount = true, maxCountToShow = maxDocumentHeaderCount } = {}
) => {
  const unreadInboxCount = showInboxCount ? getUnreadInboxCount(state) : 0;
  const unreadNotificationCount = showNotificationCount ? getUnseenCount(state) : 0;
  const count = unreadInboxCount + unreadNotificationCount;

  return getDisplayableFeedCount(state, inboxUnreadFeedId, { count, maxCountToShow });
};

type GetFulfilledUnseenThreadEdgesForFeed = (state: YammerState) => Record<string, UnseenThreadEdgesForFeed>;
export const getFulfilledUnseenThreadEdgesForFeed: GetFulfilledUnseenThreadEdgesForFeed = (state) =>
  state.unseenThreadEdges.fulfilled.byId;

type ReorderGroupIdsWithUnseenFirst = (
  unseenThreadEdges: Record<string, UnseenThreadEdgesForFeed>,
  groupIds: Group['id'][]
) => Group['id'][];
export const reorderGroupIdsWithUnseenFirst: ReorderGroupIdsWithUnseenFirst = (unseenThreadEdges, groupIds) => {
  const emptyDivided = { unseenGroupIds: [] as Group['id'][], seenGroupIds: [] as Group['id'][] };

  const dividedGroupIds = groupIds.reduce(({ unseenGroupIds, seenGroupIds }, groupId) => {
    const feedId = getId({ type: 'Group', subtype: GroupFeedType.UNSEEN, entityId: groupId });
    const unseenCount = unseenThreadEdges[feedId]?.threadEdges?.length || 0;

    return unseenCount > 0
      ? { unseenGroupIds: [...unseenGroupIds, groupId], seenGroupIds }
      : { unseenGroupIds, seenGroupIds: [...seenGroupIds, groupId] };
  }, emptyDivided);

  return dividedGroupIds.unseenGroupIds.concat(dividedGroupIds.seenGroupIds);
};

type IsInboxWithMoreThreadsOnServer = (state: YammerState) => boolean;
export const isInboxWithMoreThreadsOnServer: IsInboxWithMoreThreadsOnServer = (state) =>
  isFeedWithMoreThreadsOnServer(state, inboxUnreadFeedId);
