import { setCurrentPageName, setPageLoadNumber } from '@ms/yammer-telemetry-support';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { getPageLoadId, incrementPageLoadId } from '../../telemetry/pageLoadId';

import { PageTelemetryContextProviderProps } from './PageTelemetryContextProvider.types';
import { PageTelemetryContext, PageTelemetryContextValue, RouteProps } from './context';
import { useReportHeroComponentRenderedCallback } from './hooks';

interface ContextState extends RouteProps {
  readonly pathname?: string;
  readonly pageLoadId: number;
}

const PageTelemetryContextProvider: FC<PageTelemetryContextProviderProps> = ({ isRoutelessApp = false, children }) => {
  const hasRenderedFirstRoute = useRef(false);
  const pageLoadId = getPageLoadId();

  const [{ pathname, path, page, hero }, setRouteProps] = useState<ContextState>({
    pathname: undefined,
    pageLoadId,
    path: undefined,
    page: 'Unknown',
    hero: 'none',
  });

  const setRoute = useCallback(
    (props: RouteProps, pathname: string) => {
      if (!hasRenderedFirstRoute.current) {
        hasRenderedFirstRoute.current = true;
      } else {
        incrementPageLoadId();
      }
      setRouteProps({ ...props, pathname, pageLoadId });
    },
    [pageLoadId]
  );

  setCurrentPageName(page);
  setPageLoadNumber(pageLoadId);

  const { hasHeroComponentRendered, reportHeroComponentRendered } = useReportHeroComponentRenderedCallback({
    pathname,
    pageLoadId,
    path,
    page,
    hero,
    isRoutelessApp,
  });

  const value = useMemo<PageTelemetryContextValue>(
    () => ({
      page,
      path,
      hero,
      isRoutelessApp,
      setRoute,
      reportHeroComponentRendered,
      hasHeroComponentRendered,
    }),
    [page, path, hero, isRoutelessApp, setRoute, reportHeroComponentRendered, hasHeroComponentRendered]
  );

  return <PageTelemetryContext.Provider value={value}>{children}</PageTelemetryContext.Provider>;
};

export default PageTelemetryContextProvider;
