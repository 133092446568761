import { MenuProps, ZQMV2Props } from '@1js/officeaicopilot-chat-types';
import { reportError } from '@ms/yammer-web-support/dist/telemetry';
import { create } from 'zustand';

import { GlobalCopilotInstance, useGlobalCopilotInstanceStore } from './globalCopilotInstanceStore';

export interface GlobalCopilotState {
  /*
   * Whether the Global Copilot is open.
   */
  readonly isOpen: boolean;

  /*
   * Opens the Global Copilot. Does nothing if the Global Copilot is already open.
   * @returns A promise that resolves to the current Global Copilot instance once it is loaded and ready. Rejects if the instance is null.
   */
  open(): Promise<Exclude<GlobalCopilotInstance, null>>;

  /*
   * Closes the Global Copilot.
   */
  close(): void;

  /*
   * The zero prompt guide used in the Global Copilot.
   */
  readonly zeroPromptGuideProps: ZQMV2Props | undefined;

  /*
   * Updates the zero prompt guide used in the Global Copilot.
   */
  updateZeroPromptGuide(zeroPromptGuide: ZQMV2Props | undefined): void;

  /*
   * The prompt menu props used in the Global Copilot.
   */
  readonly menuProps: MenuProps | undefined;

  /*
   * Updates the prompt menu props used in the Global Copilot.
   */
  updateMenuProps(menuProps: MenuProps | undefined): void;

  /*
   * The session operation context data used in the Global Copilot.
   */
  readonly sessionOperationContextData: Record<string, string | null> | null;

  /*
   * Updates the session operation context data used in the Global Copilot.
   */
  updateSessionOperationContextData(sessionOperationContextData: Record<string, string | null> | null): void;
}

export const useGlobalCopilotStore = create<GlobalCopilotState>((set, get) => {
  const open = () => ({ isOpen: true });
  const close = () => {
    useGlobalCopilotInstanceStore.getState().resetInstancePromise();

    return { isOpen: false };
  };

  return {
    isOpen: false,
    open: async () => {
      set(open);

      const instance = await useGlobalCopilotInstanceStore.getState().instancePromise;

      if (instance == null) {
        reportError({
          error: new Error('GlobalCopilotInitFailed'),
          eventProperties: {
            errorCode: 'GlobalCopilotInitFailed',
          },
        });

        return Promise.reject(new Error('GlobalCopilotInitFailed'));
      }

      return instance;
    },
    close: () => set(close),
    zeroPromptGuideProps: undefined,
    updateZeroPromptGuide: (zeroPromptGuide) => set({ zeroPromptGuideProps: zeroPromptGuide }),
    menuProps: undefined,
    updateMenuProps: (menuProps) => set({ menuProps }),
    sessionOperationContextData: null,
    updateSessionOperationContextData: (sessionOperationContextData) =>
      set({
        sessionOperationContextData: {
          ...get().sessionOperationContextData,
          ...sessionOperationContextData,
        },
      }),
  };
});
