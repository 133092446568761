import { createContext, useContext } from 'react';

import type { BorderRadiusName } from '../Card/Card.types';

export const defaultCardBorderRadiusName: BorderRadiusName = 'borderRadiusXLarge';

export interface StyleGuideOptions {
  /**
   * Sets the default border radius used for cards.
   * Defaults to 'borderRadiusXLarge' (8px).
   */
  readonly cardBorderRadius: BorderRadiusName;
}

export const defaultOptions: StyleGuideOptions = {
  cardBorderRadius: defaultCardBorderRadiusName,
};

export const StyleGuideContext = createContext<StyleGuideOptions>(defaultOptions);

export type UseStyleGuideOptions = () => StyleGuideOptions;
export const useStyleGuideOptions: UseStyleGuideOptions = () => useContext(StyleGuideContext);
