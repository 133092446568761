import { makeStyles, mergeClasses } from '@griffel/react';
import { pxToRemString } from '@ms/yammer-web-core-ui/dist/util/styles/fonts';
import { fluentLayerZIndex } from '@ms/yammer-web-core-ui/dist/util/styles/zIndex';
import { tokens } from '@ms/yammer-web-core-ui/dist/util/theme/fluent9';

const useClasses = makeStyles({
  root: {
    wordWrap: 'normal',
    textTransform: 'none',
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
    display: 'block',
  },
  focused: {
    position: 'fixed',
    width: 'auto',
    height: 'auto',
    margin: '10px',
    padding: '6px 10px',
    textAlign: 'left',
    backgroundColor: tokens.colorNeutralBackground3,
    border: `solid 1px ${tokens.colorNeutralStroke1}`,
    borderRadius: '2px',
    fontSize: pxToRemString(12),
    lineHeight: pxToRemString(16),
    clip: 'auto',
    zIndex: fluentLayerZIndex + 1,
  },
  topLeft: {
    left: '0px',
    top: '50px',
  },
});

export const useClassNames = (containsFocus: boolean, alignWithContent: boolean) => {
  const classes = useClasses();

  return {
    root: mergeClasses(
      classes.root,
      containsFocus && classes.focused,
      containsFocus && !alignWithContent && classes.topLeft
    ),
  };
};
