import { useMemo } from 'react';

import {
  useCanAccessCommunicationsDashboard,
  useCanAccessCommunicationsDashboardConversationsPage,
} from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useCommsDashboardConversationsPageSettings: UseMainUIFeatureSettings<
  'communicationsDashboardFeaturedConversationsPage'
> = (options) => {
  const canAccessDashboard = useCanAccessCommunicationsDashboard();
  const canAccessConversations = useCanAccessCommunicationsDashboardConversationsPage();

  return useMemo(() => {
    const overrides = options?.overrides?.communicationsDashboardFeaturedConversationsPage ?? {};

    return {
      [uiFeatureAccessSymbol]: canAccessDashboard && canAccessConversations,
      ...overrides,
    };
  }, [options, canAccessDashboard, canAccessConversations]);
};
