import { reportUsage } from '@ms/yammer-data/dist/telemetry';
import { getFirstResource, getQueryParameter } from '@ms/yammer-web-support/dist/location';
import { useEffect } from 'react';

import { getValidAlphanumericId, getValidCopyLinkValue, getValidNumberId } from './validations';

type UseReportPageLoadedFromLinkClick = (pathname: string) => void;

export const useReportPageLoadedFromLinkClick: UseReportPageLoadedFromLinkClick = (pathname) => {
  const isNotificationsUnsubscribePage = pathname.includes('/notifications/unsubscribe');
  const emailType = getQueryParameter('email_type') as string;
  const followedUser = getQueryParameter('followed_user') as string;

  const integrationOrigin = getQueryParameter('trk_integration_origin') as string;
  const notifId = getQueryParameter('trk_notif_id') as string;
  const outlookOrigin = getQueryParameter('trk_outlook_origin') as string;
  const subEvent =
    (isNotificationsUnsubscribePage && followedUser && 'unsubscribe_page') ||
    (isNotificationsUnsubscribePage && 'notification_settings_page') ||
    (getQueryParameter('trk_event') as string);
  const scenarioType =
    isNotificationsUnsubscribePage && emailType ? emailType : (getQueryParameter('trk_scenario_type') as string);
  const threadId = getQueryParameter('trk_thread_id') as string;
  const groupId = getQueryParameter('trk_group_id') as string;
  const storylineUserId = getQueryParameter('trk_storyline_user_id') as string;
  const scenarioSubType = getQueryParameter('trk_scenario_sub_type') as string;
  const copyLink = getQueryParameter('trk_copy_link') as string;
  const partnerOrigin = getQueryParameter('trk_partner_origin') as string;
  const partnerOriginSource = getQueryParameter('trk_partner_origin_source') as string;
  const origin = integrationOrigin ?? outlookOrigin;
  const shouldIncludePartnerOrigin = !!(partnerOrigin && partnerOriginSource);
  const shouldIncludeDestination = shouldIncludePartnerOrigin;

  useEffect(() => {
    if ((origin && scenarioType && subEvent) || copyLink || shouldIncludePartnerOrigin) {
      const firstResource = getFirstResource(pathname);
      // eslint-disable-next-line no-restricted-globals
      const decodedResourceId = firstResource ? JSON.parse(atob(firstResource)).id : undefined;
      const validResourceId = getValidNumberId(decodedResourceId);

      const validThreadId = getValidNumberId(threadId);
      const validGroupId = getValidNumberId(groupId);
      const validNotificationId = getValidAlphanumericId(notifId);
      const validCopyLink = getValidCopyLinkValue(copyLink);

      reportUsage({
        eventName: 'page_loaded_from_link_click',
        eventProperties: {
          ...(storylineUserId && { storylineUserId }),
          ...(scenarioType && { scenarioType }),
          ...(subEvent && { subEvent }),
          ...(origin && { integrationOrigin: origin }),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ...(notifId && { notif_id: validNotificationId }),
          ...(threadId && { threadId: validThreadId }),
          ...(groupId && { groupId: validGroupId }),
          ...(scenarioSubType && { scenarioSubType }),
          ...(copyLink && { copyLink: validCopyLink }),
          ...(firstResource && { resourceId: validResourceId }),
          ...(shouldIncludePartnerOrigin && { partnerOrigin, partnerOriginSource }),
          ...(shouldIncludeDestination && { destination: pathname }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
