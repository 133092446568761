import queryInformationBarrierSettings from '@ms/yammer-graphql/dist/operations/queryInformationBarrierSettings.graphql';

import { ManagedApiLifecycle, getGraphqlApiRequest, managedApiAction } from '../../api';
import { normalizeUserInformationBarrierSettings } from '../../normalization/graphql/normalizeUserInformationBarrierSettings';
import { NormalizedUserInformationBarrierSettingsEntities } from '../../normalization/graphql/normalizeUserInformationBarrierSettings.types';
import { User } from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import {
  UserInformationBarrierSettingsLoadFulfilledAction,
  UserInformationBarrierSettingsLoadPendingAction,
  UserInformationBarrierSettingsLoadRejectedAction,
  UserInformationBarrierSettingsResponse,
} from './actions.types';
import { getUserInformationBarrierSettingsStatus } from './selectors';

const userInformationBarrierSettingsPending = (id: User['id']): UserInformationBarrierSettingsLoadPendingAction => ({
  type: 'USER_INFORMATION_BARRIER_SETTINGS.LOAD_PENDING',
  payload: {
    id,
  },
});

const userInformationBarrierSettingsFulfilled = (
  id: User['id'],
  entities: NormalizedUserInformationBarrierSettingsEntities,
  shouldShowInformationBarrierPublisherWarning: boolean
): UserInformationBarrierSettingsLoadFulfilledAction => ({
  type: 'USER_INFORMATION_BARRIER_SETTINGS.LOAD_FULFILLED',
  payload: {
    id,
    entities,
    shouldShowInformationBarrierPublisherWarning,
  },
});

const userInformationBarrierSettingsRejected = (
  error: unknown,
  id: User['id']
): UserInformationBarrierSettingsLoadRejectedAction => ({
  type: 'USER_INFORMATION_BARRIER_SETTINGS.LOAD_REJECTED',
  payload: {
    error,
    id,
  },
  error: true,
});

const loadUserInformationBarrierSettingsRequest = (userId: User['id']) =>
  getGraphqlApiRequest({
    body: {
      operationName: 'InformationBarrierSettingsClients',
      query: queryInformationBarrierSettings,
      variables: {
        userId,
      },
    },
  });

type UserInformationBarrierSettingsLoadLifecycle = (
  userId: User['id']
) => ManagedApiLifecycle<UserInformationBarrierSettingsResponse>;
const userInformationBarrierSettingsLoadLifecycle: UserInformationBarrierSettingsLoadLifecycle = (userId) => ({
  pending: () => userInformationBarrierSettingsPending(userId),
  fulfilled: (response) => {
    const { entities } = normalizeUserInformationBarrierSettings(response);
    const { viewer } = response.data;

    return userInformationBarrierSettingsFulfilled(
      userId,
      entities,
      viewer.shouldShowInformationBarrierPublisherWarning
    );
  },
  rejected: (error) => userInformationBarrierSettingsRejected(error, userId),
});

type LoadUserInformationBarrierSettings = (userId: User['id']) => PromiseThunkAction<void>;
export const loadUserInformationBarrierSettings: LoadUserInformationBarrierSettings =
  (userId) => async (dispatch, getState) => {
    const state = getState();
    const status = getUserInformationBarrierSettingsStatus(state, userId);

    if (status === 'pending') {
      return;
    }

    const request = loadUserInformationBarrierSettingsRequest(userId);
    const lifecycle = userInformationBarrierSettingsLoadLifecycle(userId);
    const action = managedApiAction({ request, lifecycle });

    try {
      await dispatch(action);
    } catch (error) {
      reportError({ error });
    }
  };
