import { ComponentClassNames } from '../../util/classNames';
import { memoizeFunction, mergeStyleSets } from '../../util/styling';
import type { IconSize } from '../SVGIcon';

type FluentUISystemIconClassNames = ComponentClassNames<'fluentUISystemIcon'>;

export const getClassNames = memoizeFunction(
  (
    block: boolean,
    color: string | undefined,
    size: IconSize | undefined,
    className?: string
  ): FluentUISystemIconClassNames => {
    const sizeValue = size ? `${size}px !important` : undefined;

    return mergeStyleSets({
      fluentUISystemIcon: [
        // Need to keep this class name for proper sizing when nested under Block or Text.
        'y-fluent-icon',
        {
          display: block ? 'block !important' : 'inline',
          position: 'relative',
          verticalAlign: '0',
          fill: 'currentColor',
          color,
          height: sizeValue,
          width: sizeValue,

          // Browsers don't bubble up mouse hover events. This style lets the mouse events be captured by the element
          // behind the SVG to allow the expected behavior when mouseenter/mouseleave is attached to an icon button.
          pointerEvents: 'none',
        },
        className,
      ],
    });
  }
);
