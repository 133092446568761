type UserSettingsRootPath = '/user-settings';

export const userSettingsRootPath: UserSettingsRootPath = '/user-settings';
export const userSettingsGeneralSubpath = '/general';

type NotificationsSubPath = '/notifications';
export const userSettingsNotificationsSubpath: NotificationsSubPath = '/notifications';
export const userSettingsNotificationsCommunitiesSubpath: `${NotificationsSubPath}/communities` = `${userSettingsNotificationsSubpath}/communities`;
export const userSettingsNotificationsUnsubscribeSubpath: `${NotificationsSubPath}/unsubscribe` = `${userSettingsNotificationsSubpath}/unsubscribe`;
export const userSettingsNotificationsSubscribeSubpath: `${NotificationsSubPath}/subscribe` = `${userSettingsNotificationsSubpath}/subscribe`;

export const userSettingsGeneralSettingsPath: `${UserSettingsRootPath}${typeof userSettingsGeneralSubpath}` = `${userSettingsRootPath}${userSettingsGeneralSubpath}`;
export const userSettingsNotificationsSettingsPath: `${UserSettingsRootPath}${NotificationsSubPath}` = `${userSettingsRootPath}${userSettingsNotificationsSubpath}`;
export const userSettingsNotificationsCommunityPath: `${UserSettingsRootPath}${typeof userSettingsNotificationsCommunitiesSubpath}` = `${userSettingsRootPath}${userSettingsNotificationsCommunitiesSubpath}`;
export const userSettingsNotificationsEmailSubscribeConfirmationPath: `${UserSettingsRootPath}${typeof userSettingsNotificationsSubscribeSubpath}` = `${userSettingsRootPath}${userSettingsNotificationsSubscribeSubpath}`;
export const userSettingsNotificationsEmailUnsubscribeConfirmationPath: `${UserSettingsRootPath}${typeof userSettingsNotificationsUnsubscribeSubpath}` = `${userSettingsRootPath}${userSettingsNotificationsUnsubscribeSubpath}`;

export const webUserSettingsRoutesForLeftNav = [
  userSettingsNotificationsEmailSubscribeConfirmationPath,
  userSettingsNotificationsEmailUnsubscribeConfirmationPath,
  userSettingsNotificationsCommunityPath,
  userSettingsNotificationsSettingsPath,
  userSettingsGeneralSettingsPath,
  userSettingsRootPath,
];

export const metaOSUserSettingsRoutesForLeftNav = [
  userSettingsNotificationsCommunityPath,
  userSettingsNotificationsSettingsPath,
  userSettingsGeneralSettingsPath,
  userSettingsRootPath,
];
