import { LazyRelayModuleWrapper } from '@ms/yammer-relay/dist/environment';
import React, { FC, Suspense } from 'react';

import { GlobalCopilotFloatieButton } from '../components/GlobalCopilot';

export const GlobalCopilotFloatieButtonIntegration: FC = () => (
  <Suspense fallback={null}>
    <LazyRelayModuleWrapper>
      <GlobalCopilotFloatieButton />
    </LazyRelayModuleWrapper>
  </Suspense>
);
